import styled from "styled-components";

export const FitnessSearchExerciseFormContainer = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  width: auto;
`

export const FitnessSearchExerciseForm = styled.form`
  display: block;
  justify-content: center;
  align-items: center;
`