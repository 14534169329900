import "./dashboard.styles.scss"
import Dashboard from "../../../pages/signed-in/dashboard/dashboard.component"

const DashboardRouteSignedIn = () => {
  return (
    <div>
    <Dashboard></Dashboard>
    </div>
  )
}

export default DashboardRouteSignedIn