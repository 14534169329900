export const NUTRITION_TRACKER_ACTION_TYPES = {
  SET_NUTRITION_TRACKED_DAYS: "nutrition-tracker/SET_NUTRITION_TRACKED_DAYS",
  SET_FORM_INPUT_MICRONUTRIENTS: "nutrition-tracker/SET_FORM_INPUT_MICRONUTRIENTS",
  SET_FILTER_CONDITIONS: "nutrition-tracker/SET_FILTER_CONDITIONS",

  SET_SELECTED_NUTRITION_TRACKED_DAY: "nutrition-tracker/SET_SELECTED_NUTRITION_TRACKED_DAY",
  SET_SCHEDULED_NUTRITION_TRACKED_DAYS_VIEW: "nutrition-tracker/SET_SCHEDULED_NUTRITION_TRACKED_DAYS_VIEW",

  SET_NUTRITION_TRACKED_DAYS_VIEW: "nutrition-tracker/SET_NUTRITION_TRACKED_DAYS_VIEW",
  SET_DAY_TRACKED_SEARCH_RESULT: "nutrition-tracker/SET_DAY_TRACKED_SEARCH_RESULT",
  SET_NUTRITION_TRACKED_DAYS_SUMMARY: "nutrition-tracker/SET_NUTRITION_TRACKED_DAYS_SUMMARY"
}