import styled from "styled-components";

export const NutritionTrackerContainer = styled.div`

`

export const UpdateConsumptionContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`

