import styled from "styled-components"

export const ChatBotResponseContainer = styled.div`
  /* border-radius: 1.5rem;
  box-shadow: 1px 1px 1px 1px rgb(150, 150, 150); */
  display: block;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  padding: 2%;
`