import "./nutrition-tracker.styles.scss";

import NutritionTracker from "../../../pages/signed-in/nutrition-tracker/nutrition-tracker.component";

const NutritionTrackerRouteSignedIn = () => {
  return (
    <NutritionTracker></NutritionTracker>
  );
};

export default NutritionTrackerRouteSignedIn;