import "./dashboard.styles.scss"
import Dashboard from "../../../pages/signed-out/dashboard/dashboard.component"

const DashboardRoute = () => {
  return (
    <div>
      <Dashboard></Dashboard>
    </div>
  )
}

export default DashboardRoute