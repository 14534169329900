import styled from "styled-components";

export const UpcomingExercisesContainer = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
`

export const UpcomingExercisesScheduled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const UpcomingExerciseContainer = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
`