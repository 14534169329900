// nutrient-predictor errors

// context
export const errorOnInvalidImageType = () => {
  alert("Invalid image type");
};

// api requests
export const errorOnGetNutrientPredictions = () => {
  alert("Error getting nutrient predictions")
}