import styled from "styled-components";

export const CaloriesBurnedContainer = styled.div`

`

export const CaloriesBurnedFilterContainer = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
`

export const AddActivityContainer = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
`