import CaloriesBurned from "../../../pages/signed-in/calories-burned/calories-burned.component";
import "./calories-burned.styles.scss";


const CaloriesBurnedRouteSignedIn = () => {
  return (
    <CaloriesBurned></CaloriesBurned>
  );
};

export default CaloriesBurnedRouteSignedIn;