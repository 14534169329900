import "./calories-burned.styles.scss";

import CaloriesBurned from "../../../pages/signed-out/calories-burned/calories-burned.component";

const CaloriesBurnedRoute = () => {
  return (
    <CaloriesBurned></CaloriesBurned>
  );
};

export default CaloriesBurnedRoute;