import styled from "styled-components";

export const NutritionTrackerFilterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
`

export const FilterButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`