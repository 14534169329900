import React from "react";

import UploadImage from "./upload-image/upload-image.component";

const UploadFoodImage = () => {
  return (
    <UploadImage></UploadImage>
  )
};

export default UploadFoodImage;