import styled from "styled-components";

export const SearchResultsRecipeContainer = styled.div`
  justify-content: center;
  align-items: center;
  margin: 2% 10% 2% 10%;
`
export const SearchResultsMainContainer = styled.div`
  justify-content: top;
  align-items: top;
`