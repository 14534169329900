import styled from "styled-components";

export const SearchedCaloriesBurnedActivitiesTableContainer = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  margin: 2% 0% 2% 0%;
`

export const SearchedCaloriesBurnedActivitiesTable = styled.div`
  padding: 1%;
`